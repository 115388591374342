import React from "react";

import "./enrollCard.scss"

import InternalButton from "../internalButton/internalButton"

const EnrollCard = (props) => {

    return (
        <div className={"enroll-card-container " +  props.cardClass}>
            <div className="header">
                <img alt={props.alt} src={props.cardImage} />
                <h3>{props.header}</h3>
            </div>
            <div className="content">
                {props.content}
                <div className="contact">
                    {props.fax && (
                        <p className="fax"><strong>{props.fax}</strong></p>
                    )}
                    {props.mail && (
                        <p className="mail"><strong>Mail: </strong>{props.mail}</p>
                    )}
                </div>
                <div className="btn-container">
                    {/* {props.enrollLinkText && <InternalButton url={props.btnLink} title={props.btnTitle} text={props.enrollLinkText} className={props.btnClass} />} */}
                    {props.enrollLinkText && <h3 className="text-with-arrow m-b-0"><a id={props.linkId} href={props.btnLink} target="_blank" className={"no-underline lh120 " + props.btnClass}>{props.enrollLinkText}</a></h3>}
                    {props.downloadLinkText && <a id={props.linkId} href={props.btnLink} title={props.btnTitle} target="_blank" className={props.btnClass} >{props.downloadLinkText}</a>}
                    {props.spanshDownloadLinkText && <a id={props.secondLinkId} href={props.spanishBtnLink} target="_blank" title={props.btnTitle} className={props.btnClass}>{props.spanshDownloadLinkText}</a>}
                </div>
            </div>

        </div>
    )
}

export default EnrollCard;